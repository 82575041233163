import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Styled } from '../styles/legal.styles'
import { graphql } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'

const TermosPage = () => {
  const { language } = useI18next()

  return (
    <Layout isHome>
      <SEO
        title="Termos e Condições"
        description="Termos e Condições da Next2U."
      />
      <Styled.Main>
        {language === 'en' ? (
          <>
            <h1>Terms and Conditions</h1>

            <div className="content">
              <p>
                Dinastia, with headquarters Rua Direita de Aradas, 498, 3800-004
                Aveiro PORTUGAL, legal entity number, is the owner of this
                website. The use of www.next2u.pt recognizes the general terms
                and conditions listed below and described. Its acceptance is
                essential to the use of the services and information provided
                here. If you do not agree with them, you must immediately cease
                use of this Website. www.next2u.pt is an online service to
                distribute information about properties suitable for sale,
                rental, exchange, or transfer, as well as to disclose and
                promote their characteristics and conditions, including their
                price.
              </p>

              <p>
                The company Dinastia reserves the right, at any time, without
                prior notice and with immediate effect, to modify, add, update,
                delete, partially or totally, these Terms and Conditions of Use
                and Privacy Policy. Guaranteeing, in any circumstance, the
                quality, safety, legality, veracity, and accuracy of the
                information of the respective properties and offers on the
                published website. You should periodically check the page of
                these Terms and Conditions of Use and Privacy Policy to confirm
                whether any updates or changes have been made. If you do not
                agree to any of these, please do not use this Website.
              </p>

              <p>
                <strong>Use of the website</strong>
                Users who visit the Website can access and read all the content
                contained in it, without the need to register personal data or
                information. However, only registered users or with the
                submission of completed forms to schedule visits or requests for
                commercial contacts will be contacted and informed about the
                properties and services available through the email address or
                phone submitted on their forms. They will receive updates and
                information about the services and properties available in
                Dinastia if they have previously accepted in their registration
                or submitted form, communication for that purpose. This Website,
                or any of our services, shall not be used:
              </p>

              <ul>
                <li>
                  For any reason or purpose that is illegal, defamatory,
                  offensive, harmful, abusive, invasive of another’s privacy or
                  objectionable;
                </li>
                <li>
                  In a way that may prejudice or disrupt this Website or any of
                  our services, or the peaceful use by other users;
                </li>
                <li>
                  For transmitting viruses or another malicious coding which may
                  damage or disrupt computer equipment or systems (hardware or
                  software) or telecommunications equipment;
                </li>
                <li>
                  In a way that may offend others or harm the Dinastia, such as
                  sending or making available any illegal, false, misleading,
                  threatening, malicious, abusive, defamatory, injurious,
                  invasive privacy content, harmful or that may adversely affect
                  the image or activity of the Dinastia;
                </li>
                <li>
                  To promote any type of commercial activity, or to send or make
                  available information or content that belongs to third parties
                  and that has no right to use, such as content protected by
                  third-party copyrights or content containing personal data of
                  third parties.
                </li>
              </ul>

              <p>
                In general, the User should use the Website in a responsible,
                prudent, and careful manner, and should not disturb or damage
                its continuity or integrity. Dinastia is not responsible for any
                direct, indirect, or negligent damages arising from the use of
                the information contained in this Website or the Website itself
                as well as the harmful or disruptive conduct of the User.
              </p>

              <p>
                <strong>Information and contents of this website</strong>
                All contents and information published on this website are the
                responsibility of the Dinastia that ensures their veracity and
                transparency. Striving to provide useful, up-to-date, and
                reality-adjusted information. However, it may not be correct or
                fully updated. In this sense, we cannot be held responsible for
                any actions taken as a result of or based on the information
                contained in or provided by this Website, whether in a contract
                action, negligence, or other unlawful action. Through
                commercial@next2u.pt you can always send your suggestions or
                errata. Dinastia is reserved the right to move, change, delete
                the Website, or content thereof (including the content of
                registered users, if any), at any time and for any reason. You
                must therefore keep a copy of any content you submit to the
                Website that you wish to maintain. After you have uploaded the
                content, you can choose to delete the content at any time. If
                you would like to report your concerns regarding any content
                available on this site, please send an email directly to
                sales@next2u.pt.
              </p>

              <p>
                <strong>Data logging</strong>
                It is allowed the registration of data of all users interested
                in receiving information or scheduling a visit on a particular
                property or service advertised here and published. Registration
                or completion of forms is free of charge. To register as a user
                interested in a particular offer, property, or service you must
                complete the form and select the desired one: visit appointment
                or request information. The personal data of each user will be
                kept as long as the subscription and/or requests of the user are
                active, to improve the services offered so that after its
                expiration they will be depersonalized and not identifiable. You
                are allowed to question the data and may request the deletion,
                rectification, updating, or completion of the data. These
                requests must be sent to commercial@next2u.pt. We suggest
                consulting the Privacy Policy of this Website.
              </p>

              <p>
                <strong>Use of hyperlinks</strong> The content of this Website
                may include Hyperlinks (hyperlinks) to other sites that are not
                operated by Dinastia. Dinastia is not responsible for the
                content of any other Website, including those that give access
                to this or whose access has been obtained through our Website.
                Dinastia does not endorse any Linked Sites or the
                products/services that are provided on these Sites. Content on
                linked sites may change over time. It is recommended to check
                the terms and policies of linked sites. By providing on this
                Website a hyperlink to an external Website, Dinastia does so in
                good faith that it contains or may contain relevant information
                additional to that presented on its Website. The existence of
                this hyperlink does not imply or presuppose its review and
                approval by the Dinastia - in certain instances: a hyperlink may
                lead to access a Website that presents information contradictory
                to what is presented on our Website or another one belonging to
                Dinastia or its subsidiaries.
              </p>

              <p>
                <strong>Our Responsibility and Contacts</strong>
                Dinastia is not responsible for any direct, indirect, or
                specific loss or damage or any other consequences arising from
                access to or use of this website, or any information contained
                therein. Nevertheless, we are available to discuss with the user
                the implications of any issue that may arise, and you should
                contact us through our website at commercial@next2u.pt
                (contacts), through the address Rua Direita de Aradas, 498,
                3800-012 Aveiro PORTUGAL, from our telephone +351 933 167 606,
                or via the commercial email address@next2u.pt. By accessing our
                contact area, you will be accepting that the information you
                provide to us, through this contact, will be held by the
                Dynasty, which may use it as it sees fit and under no
                circumstances any right or payment in favor of such use. To the
                maximum extent permitted by law, all warranties, express or
                implied, charges or warranties, representations, and other terms
                and conditions of any kind relating to this Website or matter
                not contained in these Terms and Conditions are expressly
                excluded. Nothing in these Terms of Use excludes, restricts, or
                modifies any right or remedy, or any warranties, or other term
                or condition, implied or imposed by any legislation that cannot
                be legally excluded or limited. This may include the Consumer
                Rights legislation, in Portuguese territory, which contains
                guarantees that protect Consumers from goods and services in
                certain circumstances. The liability of one party to another
                party is reduced to the extent that the acts or omissions of the
                other party, or those of a third party, contribute to or cause
                such liability. Although we have developed the corresponding
                actions to keep this Website free of computer viruses or another
                harmful coding, we cannot guarantee that this is the case. You
                should therefore use security software, updated at all times, to
                protect your computer from such malicious software. Although we
                make every reasonable commercial effort to keep this Website
                available, we do not guarantee that it is accessible or
                available on a constant time basis or that access is not
                interrupted or error-free. Dinastia is the entity responsible
                for the collection and processing of personal data. It respects
                the privacy of all users and undertakes to protect all
                information submitted by each user following the national and
                Community legislation in force. All users can browse this
                website without the obligation to make or submit any data
                record. When you submit your data, if you wish to receive news,
                campaigns, or other information associated with real estate or
                our services in addition to the commercial communications you
                have requested, we ask you to agree to send it. The provision of
                personal data is necessary only if you wish to make a visiting
                appointment, request for information, or contact request and
                will always be guaranteed, following the law, the right of
                access, rectification, and cancellation of any data provided,
                This right may be exercised in writing at any time through the
                commercial email or by clicking on the subscription termination
                link at the bottom of the email.
              </p>

              <p>
                <strong>Data logging</strong> We collect personal information
                through the voluntary filing of the forms available for booking
                visits, requests for information about real estate or services,
                and contact requests. By contacting us through email,
                subscribing to information free obliges us to collect your email
                address, name, and other specific personally identifiable data,
                usually translated by name and contact number. When requesting
                sensitive personal data, we will ensure that its collection and
                use will be carried out in strict compliance with applicable
                data collection and protection legislation. If we intend to use
                your data beyond the purposes set out initially, we will ask for
                your consent. You may choose to give this consent in advance of
                initial data collection.
              </p>

              <p>
                <strong>Data collected automatically</strong>
                Visitors' IP domains and addresses are automatically registered.
                This information does not identify the user but only the
                computer used to access the site. This data is analyzed globally
                to verify where in the world the site is being used to ensure
                its coverage, allowing our user service to be improved. The
                personal data of each user are not collected and analyzed in
                this process.
              </p>

              <p>
                <strong>Data Retention</strong>
                The data will be kept as long as the user’s subscription is
                active to improve the services offered so that after their
                expiration they will be depersonalized and not identifiable.
                Additional information about you will only be collected in
                addition to the above for contractual purposes adjacent to the
                real estate brokerage activity.
              </p>

              <p>
                <strong>Access to personal data</strong>
                To request clarification about access to personal data, or to
                clarify questions related to the data privacy policy, practiced
                in the Dinastia’s, contact us through the commercial e-mail: Rua
                Direita de Aradas, 498, 3810 004 Aveiro, PORTUGAL. We will
                provide you with a copy of the personal data we hold about you
                within a maximum of 40 days from your request to this website;
                to this end, you will be required to provide proof of
                identification. You are allowed to question the data and, where
                appropriate, you can: erase the data, rectify it, amend it or
                complete it. We reserve the right to refuse to provide a copy of
                your data, requiring us to justify this decision. You may
                contest our decision to refuse to provide a copy of your data.
                You may cease subscribing to your registration at any time, and
                this is a simple process. By providing your data at the
                beginning, we provide you with the procedures for your
                cancellation, either through a link or by a commercial email
                address@next2u.pt. By making your data available for purposes
                other than those indicated, we offer you the opportunity to give
                your prior consent.
              </p>

              <p>
                <strong>Security</strong>
                Security policies, rules, and technical measures have been
                implemented to protect personal data under our control, which
                are fully following data protection legislation, appropriate to
                the jurisdiction applicable to the website. Security measures
                are designed to prevent unauthorized access, improper use,
                unauthorized modification, and unlawful destruction or
                accidental loss.
              </p>

              <p>
                <strong>Compliance/compliance with privacy</strong>
                Our privacy policy is under applicable Portuguese law, namely
                Law 67/98 of 26/10. Data protection legislation is subject to
                review, so we recommend that you regularly consult our privacy
                statement. For this purpose, please refer to www.next2u.pt. This
                website complies with national and Community legislation. The
                Portuguese courts will have exclusive jurisdiction over any
                dispute arising from the use of this website.
              </p>

              <p>
                <strong>Hyperlinks</strong>
                Dynasty websites may provide links to external websites for your
                convenience and information. By accessing these links you will
                leave the Dinastia’s sites, so the Dinastia’s: <br />- Does not
                control such websites or their privacy policies, which may be
                different from those of the Dinastia’s; <br />- Does not approve
                of or represent these sites. Dinastia’s Privacy Statement does
                not cover personal data that may be transferred by the user to
                websites outside the Dinastia’s. We recommend that you review
                the privacy policies of any company before submitting your
                personal information. Some outside companies may choose to share
                their personal information with the Dinastia’s, so this sharing
                will be governed by that company’s privacy policy.
              </p>
            </div>
          </>
        ) : (
          <>
            <h1>Termos e Condições</h1>

            <div className="content">
              <p>
                Dinastia, com sede Rua Direita de Aradas, 498, 3800-004 Aveiro
                PORTUGAL, pessoa coletiva número , é detentora do presente
                website. A utilização do www.next2u.pt reconhece os termos e
                condições gerais abaixo enumerados e descritos. A sua aceitação
                é absolutamente indispensável à utilização dos serviços e
                informações aqui prestados. Caso não concorde com os mesmos,
                deverá cessar imediatamente a utilização deste Website.
                www.next2u.pt é um serviço online com o objetivo de distribuir
                informação sobre imóveis aptos a venda, arrendamento, permuta ou
                trespasse bem como divulgar e promover as respetivas
                características e condições dos mesmos incluindo o respetivo
                preço.
              </p>

              <p>
                À Dinastia reserva-se o direito de, a qualquer momento, sem
                necessidade de aviso prévio e com efeitos imediatos, alterar,
                adicionar, atualizar, eliminar, parcial ou totalmente, os
                presentes Termos e Condições de Utilização e Política de
                Privacidade. Garantindo, em qualquer circunstancia, a qualidade,
                segurança, legalidade, veracidade e exatidão das informações dos
                respetivos imóveis e ofertas no website publicados. O utilizador
                deve consultar periodicamente a página destes Termos e condições
                de utilização e Política de Privacidade para confirmar se foram
                efetuadas quaisquer atualizações ou alterações. Caso não
                concorde com alguma destas não deve utilizar o presente Website.
              </p>

              <p>
                <strong>Utilização do Website</strong>
                Os Utilizadores que visitam o Website podem aceder e ler todo o
                conteúdo contido no mesmo, sem necessidade de registar dados ou
                informações pessoais. No entanto, apenas os utilizadores
                registados ou com a submissão de formulários preenchidos para
                marcação de visitas ou pedidos de contactos comerciais serão
                contactados e informados sobre os imóveis e serviços disponíveis
                através do endereço eletrónico ou telefone previamente
                submetidos em formulários próprios. Receberão atualizações e
                informações sobre os serviços e imóveis disponíveis na Dinastia,
                no caso de terem previamente aceite no respetivo registo ou
                formulário submetido, comunicação para esse efeito. Este
                Website, ou qualquer um dos nossos serviços, não deverá ser
                utilizado:
              </p>

              <ul>
                <li>
                  Por qualquer razão ou finalidade que seja ilegal, difamatória,
                  ofensiva, prejudicial, abusiva, invasiva da privacidade de
                  outrem ou censurável;
                </li>
                <li>
                  De forma que possa prejudicar ou interromper este Website ou
                  qualquer um dos nossos serviços, ou a utilização pacífica por
                  parte de outros utilizadores;
                </li>
                <li>
                  Para transmitir vírus ou outra codificação maliciosa que
                  possam danificar ou perturbar equipamento ou sistema
                  informático (hardware ou software) ou equipamento de
                  telecomunicações;
                </li>
                <li>
                  De forma que possa ofender outrem ou prejudicar a Dinastia,
                  designadamente, enviar ou disponibilizar qualquer conteúdo
                  ilegal, falso, enganoso, ameaçador, maldoso, abusivo,
                  difamatório, injurioso, invasivo da privacidade, prejudicial
                  ou ainda que possa afetar negativamente a imagem ou atividade
                  da Dinastia;
                </li>
                <li>
                  Para promover qualquer tipo de atividade comercial, ou enviar
                  ou disponibilizar informação ou conteúdos que pertencem a
                  terceiros e que não tem o direito de utilizar, como, por
                  exemplo, conteúdos protegidos por direitos de autor de
                  terceiros ou conteúdos que contenham dados pessoais de
                  terceiros.
                </li>
              </ul>

              <p>
                Em geral, o Utilizador deve utilizar o Website de forma
                responsável, prudente e cuidadosa, não devendo perturbar ou
                danificar a continuidade ou integridade do mesmo. A Dinastia não
                é responsável por quaisquer danos diretos, indiretos e ou
                negligentes decorrente do uso da informação contida neste
                Website ou do próprio Website bem como da conduta prejudicial ou
                perturbadora do Utilizador.
              </p>

              <p>
                <strong>Informações e conteúdos deste website</strong>
                Todos os conteúdos e informações publicados no presente website
                são da responsabilidade da Dinastia que zela pela veracidade e
                transparência dos mesmos. Esforçando-se para oferecer
                informações úteis, atualizadas e ajustadas à realidade. No
                entanto, a mesma pode não estar correta ou completamente
                atualizada. Neste sentido, não nos podemos responsabilizar por
                quaisquer ações tomadas como resultado de ou com base nas
                informações contidas ou fornecidas por este Website, seja numa
                ação de contrato, negligência ou outra ação ilícita. Através
                comercial@next2u.pt poderá sempre enviar as suas sugestões ou
                erratas. À Dinastia está reservado o direito de mover, alterar,
                excluir o Website, ou conteúdo do mesmo (incluindo o conteúdo de
                utilizadores registados, se o caso), a qualquer momento e por
                qualquer motivo. Deve, portanto, manter uma cópia de qualquer
                conteúdo que enviar para o Website, que deseje manter. Depois de
                ter carregado o conteúdo, pode optar por excluir o conteúdo a
                qualquer momento. Caso deseje reportar as suas preocupações
                relativas a qualquer conteúdo disponível neste site, por favor,
                envie um e-mail diretamente para comercial@next2u.pt.
              </p>

              <p>
                <strong>Registo de dados</strong>É permitido o registo de dados
                de todos os utilizadores interessados em receber informações ou
                marcar uma visita sobre determinado imóvel ou serviço aqui
                anunciado e publicado. O registo ou preenchimento de formulários
                são gratuitos. Para se registar como utilizador interessado em
                determinada oferta, imóvel ou serviço deve preencher o
                formulário e selecionar o pretendido: marcação de visita ou
                pedido de informação. Os dados pessoais de cada utilizador serão
                mantidos enquanto a subscrição e/ou pedidos do utilizador
                estiverem ativos, com o objetivo de melhorar os serviços
                oferecidos, pelo que após a sua caducidade serão
                despersonalizados e não identificáveis. É-lhe permitido
                questionar os dados e poderá solicitar a eliminação dos dados,
                retificação, atualização ou completar os mesmos. Estes pedidos
                devem ser encaminhados para comercial@next2u.pt. Sugerimos a
                consulta da Política de Privacidade do presente Website.
              </p>

              <p>
                <strong>Utilização de Hyperlinks</strong>O conteúdo deste
                Website pode incluir Hyperlinks (hiperligações) para outros
                Sites que não são operados pela Dinastia. A Dinastia não é
                responsável pelo conteúdo de qualquer outro Website, incluindo
                aqueles que dêem acesso ao presente ou cujo acesso tenha sido
                conseguido através do nosso Website. A Dinastia não endossa
                quaisquer Sites vinculados ou os produtos/serviços que são
                fornecidos nesses Sites. Os conteúdos nos sites vinculados podem
                mudar ao longo do tempo. Recomenda-se a verificação dos termos e
                políticas dos Sites vinculados. Ao fornecer neste Website um
                hyperlink para um Website externo, a Dinastia fá-lo na boa-fé de
                que este contenha ou possa conter informações relevantes
                adicionais às apresentadas no seu próprio Website. A existência
                desse hyperlink não implica nem pressupõe a sua revisão e
                aprovação por parte da Dinastia - em determinadas instâncias, um
                hyperlink poderá levá-lo a aceder a um Website que apresente
                informação contraditória à apresentada no nosso Website ou a
                outro pertencente à Dinastia ou às suas subsidiárias.
              </p>

              <p>
                <strong>A Nossa Responsabilidade e Contactos</strong>A Dinastia
                não é responsável, por perdas ou danos diretos, indiretos ou
                específicos ou quaisquer outras consequências provenientes do
                acesso ou utilização deste Website, nem de qualquer informação
                no mesmo contida. Não obstante, estamos disponíveis para
                discutir com o utilizador as implicações de qualquer questão que
                venha a surgir, devendo contactar-nos através do nosso site em
                comercial@next2u.pt (contactos), através da morada Rua Direita
                de Aradas, 498, 3800-012 Aveiro PORTUGAL, do nosso telefone +351
                933 167 606, ou através do endereço de email
                comercial@next2u.pt. Ao aceder à nossa área de contacto, o
                utilizador estará a aceitar que a informação que nos presta,
                através desse contacto, passará a ser detida pela Dinastia, que
                a poderá utilizar nos termos que entender mais adequados, não
                havendo lugar, em situação alguma, a qualquer direito ou
                pagamento a seu favor por essa utilização. Até ao limite máximo
                permitido por lei, todas as garantias, explícitas ou implícitas,
                encargos ou avais, representações e outros termos e condições de
                qualquer tipo relacionada com este Website ou matéria não
                contida nestes Termos e Condições estão expressamente excluídas.
                Nada nestes Termos de Utilização exclui, restringe ou modifica
                qualquer direito ou recurso, ou quaisquer garantias, ou outro
                termo ou condição, implícita ou impostas por qualquer legislação
                que não possam ser legalmente excluídas ou limitadas. Isso pode
                incluir a legislação referente aos direitos do Consumidor, em
                território Português, que contém garantias que protegem os
                Consumidores de bens e serviços em determinadas circunstâncias.
                A responsabilidade de uma parte para com outra parte é reduzida
                na medida em que os atos ou omissões da outra parte, ou aqueles
                de uma terceira parte, contribuem para ou causam tal
                responsabilidade. Apesar de desenvolvermos as correspondentes
                ações para manter este Website livre de vírus informáticos ou
                outra codificação danosa, não podemos garantir que este seja o
                caso. Deverá, portanto, utilizar um software de segurança,
                atualizado a todo o tempo, para proteger o seu computador de
                tais softwares maliciosos. Apesar de desenvolvermos todos os
                esforços comerciais razoáveis para manter este Website
                disponível, não garantimos que o mesmo esteja acessível ou
                disponível numa base temporal constante ou que o acesso não seja
                interrompido ou isento de erros. A Dinastia é a entidade
                responsável pela recolha e tratamento de dados pessoais.
                Respeita a privacidade de todos os utilizadores e compromete-se
                em proteger todas as informações submetidas por cada utilizador
                em conformidade com a legislação nacional e comunitária em
                vigor. Todos os utilizadores podem navegar neste website sem a
                obrigatoriedade de efetuar ou submeter qualquer registo de
                dados. Quando submete os seus dados, caso pretenda receber
                novidades, campanhas ou outros associados a imóveis ou aos
                nossos serviços além das comunicações comerciais por si
                solicitadas pedimos que concorde com o envio da mesma. O
                fornecimento de dados pessoais apenas é necessário no caso de
                pretender efetuar uma marcação de visita, um pedido de
                informações ou pedido de contacto e será sempre garantido, nos
                termos da lei, o direito de acesso, retificação e anulação de
                qualquer dado fornecido, podendo aquele direito ser exercido por
                escrito, a qualquer momento, através do email
                comercial@next2u.pt ou clicando no link de cessação da
                subscrição, na parte inferior do e-mail.
              </p>

              <p>
                <strong>Registo de dados</strong>
                Recolhemos informação pessoal, via o preenchimento voluntário
                dos devidos formulários dispostos para marcação de visitas,
                pedidos de informações sobre imóveis ou serviços e pedidos de
                contacto. Ao contactar-nos através de e-mail, subscrever
                informações grátis, obriga-nos à recolha do seu endereço de
                e-mail, nome e outros dados específicos de identificação
                pessoal, traduzidos normalmente pelo nome e número de contacto.
                Ao pedir dados pessoais sensíveis, assegurar-nos-emos que a sua
                recolha e utilização será feita no rigoroso cumprimento da
                legislação aplicável à recolha e proteção de dados. Se
                pretendermos utilizar os seus dados pessoais para além dos fins
                estabelecidos inicialmente, pediremos o seu consentimento.
                Poderá escolher antecipadamente dar esse consentimento aquando
                da recolha inicial de dados. Dados recolhidos automaticamente Os
                domínios e endereços de IP dos visitantes são registados
                automaticamente. Esta informação não identifica o utilizador,
                mas apenas o computador utilizado no acesso ao site. Estes dados
                são analisados a nível global para verificar em que local do
                mundo é que o site está a ser utilizado, de forma a assegurar a
                sua cobertura, permitindo que o nosso serviço ao utilizador
                possa ser melhorado. Os dados pessoais de cada utilizador não
                são objeto de recolha e análise neste processo.
              </p>

              <p>
                <strong>Retenção de dados</strong>
                Os dados serão mantidos enquanto a subscrição do utilizador
                estiver ativa, com o objetivo de melhorar os serviços
                oferecidos, pelo que após a sua caducidade serão
                despersonalizados e não identificáveis. Apenas serão recolhidas
                informações adicionais acerca do utilizador para além das
                mencionadas acima para efeitos contratuais adjacentes à
                atividade de mediação imobiliária.
              </p>

              <p>
                <strong>Acesso aos dados pessoais</strong>
                Para solicitar esclarecimentos acerca do acesso aos dados
                pessoais, ou para o esclarecimento de questões relacionadas com
                a política de privacidade de dados, praticada na Dinastia,
                contacte-nos através do e-mail comercial@next2u.pt, ou utilize o
                seguinte endereço postal: Rua Direita de Aradas, 498, 3810 004
                Aveiro, PORTUGAL. Fornecer-lhe-emos uma cópia dos dados pessoais
                que dispomos sobre si num prazo máximo de 40 dias, a contar do
                seu pedido feito para este site; para este fim, ser-lhe-á
                exigida uma prova de identificação. É-lhe permitido questionar
                os dados e, onde apropriado, poderá: apagar os dados,
                retificá-los, emendá-los ou completá-los. Reservamo-nos ao
                direito de recusa de fornecimento da cópia dos dados pessoais do
                utilizador, obrigando-nos a apresentar uma justificação para
                essa decisão. O utilizador poderá contestar a nossa decisão de
                recusa de fornecimento de cópia com os seus dados pessoais.
                Revelação e escolha do visitante O utilizador poderá cessar a
                subscrição do seu registo a qualquer momento, sendo este um
                processo simples. Ao disponibilizar os seus dados pessoais no
                início, fornecemos-lhe os procedimentos para o seu cancelamento,
                quer através de um link, quer através da disponibilização de um
                endereço de e-mail comercial@next2u.pt. Ao disponibilizarmos os
                dados pessoais do utilizador para propósitos diferentes dos
                indicados, oferecemos a oportunidade ao utilizador de dar a sua
                autorização prévia.
              </p>

              <p>
                <strong>Segurança</strong>
                Foram implementadas políticas de segurança, regras e medidas
                técnicas para proteger os dados pessoais sob o nosso controlo,
                que estão inteiramente de acordo com a legislação de proteção de
                dados, apropriada à jurisdição aplicável ao site. As medidas de
                segurança estão desenhadas para prevenir acessos não
                autorizados, utilizações impróprias, modificações não
                autorizadas e destruições ou perdas acidentais ilícitas.
              </p>

              <p>
                <strong>Cumprimento/conformidade de privacidade</strong>A nossa
                política de privacidade está de acordo com a legislação
                portuguesa aplicável, nomeadamente a Lei n.º 67/98, de 26/10. A
                legislação de proteção de dados está sujeita a revisão, pelo que
                recomendamos ao utilizador a consulta regular da nossa
                declaração de privacidade. A título meramente indicativo, poderá
                para este efeito consultar o site www.next2u.pt. Este website
                encontra-se em conformidade com a legislação nacional e
                comunitária. Os tribunais portugueses terão jurisdição exclusiva
                sobre qualquer disputa proveniente da utilização deste website.
              </p>

              <p>
                <strong>Hyperlinks</strong>
                Os websites da Dinastia poderão fornecer links para sites
                externos para sua conveniência e informação. Ao aceder a esses
                links sairá dos sites da Dinastia , pelo que, a Dinastia : não
                controla esses websites nem as suas políticas de privacidade,
                que poderão ser diferentes das da Dinastia ; não aprova nem
                representa estes sites. A Declaração de Privacidade da Dinastia
                não cobre os dados pessoais eventualmente cedidos pelo
                utilizador para sites externos à Dinastia Recomendamos-lhe que
                reveja as políticas de privacidade de qualquer companhia antes
                de submeter a sua informação pessoal. Algumas companhias
                externas poderão escolher partilhar a sua informação pessoal com
                a Dinastia , pelo que esta partilha será regida pela política de
                privacidade dessa companhia.
              </p>
            </div>
          </>
        )}
      </Styled.Main>
    </Layout>
  )
}

export default TermosPage

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["global"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
