import styled from 'styled-components'

const Main = styled.main`
  position: relative;
  width: 100%;
  padding: ${({ theme }) => theme.spacingM};
  margin-top: ${({ theme }) => theme.spacingXL};

  h1 {
    font-family: ${({ theme }) => theme.fontFamilySecondary};
    font-size: 3.5rem;
    letter-spacing: 0.06rem;
    text-transform: uppercase;
    text-align: center;
  }

  .content {
    width: 75%;
    margin: ${({ theme }) => `${theme.spacingM} auto 0 auto`};
    font-size: 2rem;
    line-height: 3rem;

    p {
      margin-bottom: ${({ theme }) => theme.spacingS};
    }

    strong {
      display: block;
      font-size: 2.1rem;
      margin-bottom: ${({ theme }) => theme.spacingXXS};
    }

    ol,
    ul {
      margin: ${({ theme }) =>
        `${theme.spacingXS} 0 ${theme.spacingXS} ${theme.spacingS}`};
    }
  }

  @media screen and (max-width: 1000px) {
    margin-top: 20rem;

    .content {
      width: 90%;
    }
  }

  @media screen and (max-width: 500px) {
    padding: ${({ theme }) => theme.spacingS};

    .content {
      width: 100%;
    }
  }

  @media screen and (max-width: 350px) {
    margin-top: 28rem;
    padding: ${({ theme }) => theme.spacingXS};
  }
`

export const Styled = {
  Main,
}
